import Vue from "vue"
import vueRouter from "vue-router"

Vue.use(vueRouter)

let originPush = vueRouter.prototype.push;
let originreplace = vueRouter.prototype.replace;

// 重写push
vueRouter.prototype.push = function (location, resolve, rereject) {
   if (resolve && rereject) {
      originPush.call(this, location, resolve, rereject)
   } else {
      originPush.call(this, location, () => {
      }, () => {
      })
   }
}
// 重写replace
vueRouter.prototype.replace = function (location, resolve, rereject) {
   if (resolve && rereject) {
      originreplace.call(this, location, resolve, rereject)
   } else {
      originreplace.call(this, location, () => {
      }, () => {
      })
   }
}


const routes = [
   {
      path: "/",
      name: "index",
      component: () => import("@/view/index/index.vue"),
      meta: {
         nav: 'index'
      }

   },
   {
      path: "/news",
      name: "news",
      component: () => import("@/view/news/index.vue"),
      meta: {
         nav: 'news'
      }

   },
   {
      path: "/verify",
      name: "verify",
      component: () => import("@/view/verify/index.vue"),
      meta: {
         nav: 'verify'
      }
   },
   {
      path: "/news/detail",
      name: "news_detail",
      component: () => import("@/view/news/detail.vue"),
      meta: {
         nav: 'news'
      }

   },
   {
      path: "/about",
      name: "about",
      component: () => import("@/view/aboutUs/index.vue"),
      meta: {
         nav: 'about'
      }

   },
   {
      path: "/innovations",
      name: "innovations",
      component: () => import("@/view/innovations/index.vue"),
      meta: {
         nav: 'innovations'
      }

   },
   {
      path: "/products",
      name: "products",
      component: () => import("@/view/products/index.vue"),
      meta: {
         nav: 'products'
      },
      children: [{
         path: "/products",
         name: "productsCate",
         component: () => import("@/view/products/categroy.vue"),
         meta: {
            nav: 'products'
         }
      }, {
         path: "/products/detail",
         name: "productsDetail",
         component: () => import("@/view/products/detail.vue"),
         meta: {
            nav: 'products'
         }
      }]

   },
   {
      path: "/checkCode",
      name: "checkCode",
      component: () => import("@/view/checkCode/index.vue"),
      meta: {
         nav: 'CHECKCODE'
      }

   },
   {
      path: "/analysis",
      name: "analysis",
      component: () => import("@/view/analysis/index.vue"),
      meta: {
         nav: 'analysis'
      }

   },
   {
      path: "/contact",
      name: "contact",
      component: () => import("@/view/contact/index.vue"),
      meta: {
         nav: 'contact'
      }

   },

]
const router = new vueRouter({
   mode: "hash",
   routes
})





export default router
