<template>
  <div id="app">
    <div class="wrapper">
      <top-nav></top-nav>
      <div class="dis_b " id="content-main">
        <div class="side-left">
          <header v-if="$route.meta.nav != 'products' && $route.meta.nav != 'index'">{{ $route.meta.nav }}</header>
          <router-view></router-view>
        </div>
        <adv v-if="$route.meta.nav != 'index'&&$route.meta.nav != 'verify'"></adv>
      </div>
      <footer-nav></footer-nav>
    </div>
  </div>
</template>

<script>
import { RouterView } from 'vue-router';
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    RouterView
  }
}
</script>

<style>
@import url("@/assets/css/flex.css");
@import url("@/assets/css/iconfont.css");
@import url("@/assets/css/main.css");

* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.wrapper {
  width: 960px;
  max-width: 100vw;
  min-width: 320px;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.side-left {
  flex: 1;
  width: 100%;
}

#content-main {
  min-height: 60vh;
  width: 100%;
  max-width: 100%;
}

li {
  list-style: none;
  /* font-size: 12px; */
}

p {
  margin: 1em 0;
  text-align: left;
  line-height: 1.5;
  font-size: 12px;

}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

input[type=text] {
  border: 1px solid white;
  height: 16px;
  font-size: 12px;
  padding: 5px;
  outline: none;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  box-sizing: border-box;
}

input:checked {
  border: 1px solid white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1em;
}
</style>
