<template>
    <div v-if="web">
        <section id="partners">
            <!-- <img src="/images/last/partners.png" alt="our partners" /> -->

            <ul class="dis_c_c" style="flex-wrap: wrap;">
                <li class="partner" v-for="(item, index) in web.dealer_logo" :key="index">
                    <img :src="item" alt="" v-if="web.dealer_logo">
                </li>
            </ul>
            <!-- <div class="last_partner dis_c_c">
                <img src="@/assets/images/anaboliclab.jpg" alt="">
            </div> -->
        </section>
        <footer id="footer-main">

            <div class="links dis">

                <div class="block b1" v-for="(item, index) in list" :key="index">
                    <h4>{{ item.title }}</h4>
                    <ul>
                        <li v-for="(item2, index2) in item.product_list" :key="index2" @click="productBetail(item2.id)">
                            {{ item2.title }}</li>
                    </ul>
                </div>

                <div class="cf"></div>
            </div>
            <div class="pre-footer">
                <div class="logo-footer">
                    <img :src="web.logo || ''" v-if="web.logo">
                </div>
                <div class="socials">
                </div>
            </div>

            <hr>

            <p class="copy">© Copyright Reinziel Biotech, Germany,2019- 2023</p>


            <div class="bottom-light"></div>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            web: null
        }
    },
    mounted() {
        this.getFooter()
    },
    updated() {
    },
    methods: {
        productBetail(id) {
            this.$router.push({
                path: '/products/detail',
                query: {
                    id: id
                }
            })
        },
        stop() {
            console.log('stop')
        },
        getFooter() {
            this.$API.GetWebInfo().then(res => {
                this.web = res.data.data
            })
            this.$API.GetBottomProduct().then(res => {
                let arr = []
                res.data.data.forEach(item => {

                    item.son_list.forEach(item2 => {
                        arr.push(item2)
                    })
                })
                this.list = arr
            })
        },
        routeTo(name) {
            this.$router.push({
                name: name
            })
        }
    }
}
</script>

<style>
#partners {
    margin: 20px 0;
    padding: 20px;
    box-sizing: border-box;
}

.partner {
    width: 8%;
}

.last_partner {
    margin: 20px 0;

}

.last_partner img {
    width: 20%;
}

#footer-main {
    border-top: 1px solid rgba(100, 100, 100, 0.5);
}

#footer-main .links {
    padding-top: 45px;
    flex-wrap: wrap;
    justify-content: center;
}

#footer-main .links .block {
    width: 145px;
    font-family: sans-serif;
    font-size: 10px;
    margin-bottom: 10px;
}

#footer-main .links .block ul {
    margin-bottom: 10px;
}

element.style {}

#footer-main .links .block h4 {
    font-family: sans-serif;
    color: #57585b;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 3px;
    letter-spacing: .5px;
}

#footer-main .links .block {
    color: #57585b;
}

.copy {
    font-family: sans-serif;
    font-size: 10px;
    color: #57585b;
    margin-bottom: 0;
}

#footer-main .logo-footer {
    padding-top: 20px;
}

#footer-main .logo-footer img {
    height: 20px;
    width: auto;
}

@media screen and (max-width: 500px) {
    .partner {
        width: 12%;

    }
}
</style>