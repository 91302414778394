export const lang = {
    about: 'Um uns.',
    news: 'Nachrichten.',
    innvoations: "innvoations",
    products: 'Produkte.',
    checkCode: 'Fehlerbestätigung läuft',
    analysis: 'Analyse.',
    contact: 'Vernetzt.',
    check: 'check',
    check_text1: 'Unsere produkte haben einen speziellen kodex, der sie schützt. Hier können sie die echtheit der gekauften Waren überprüfen, indem sie einen code eingeben und auf den namen checken klicken',
    check_text2: 'Geben Sie hier den Qualitätscode und die Chargennummer des Produkts ein',
    analysis_text1: 'Testergebnisse sind da.',
    analysis_text2: 'Alle fälschen schen tests werden Von einem unabhängigen gmp-zugelassenen labor Simec AG (www.simec) durchgeführt. Alles ist überprüfbar! Jeder bericht enthält eine eigene nummer; So wie c3480: ein analkarnizisches farmprofil. Sie können den Simec jederzeit direkt kontaktieren und eine bestimmte berichterstattungsnummer vorlegen, damit sie die echtheit des berichts überprüfen können',
    show_filter: 'SHOW FILTER',
    analysis_text3: 'Active component',
    search: "SEARCH",
    contact_text1: 'Zu pharmacom LABS sind die allgemeinen fragen, die sie in der tabelle ausfüllen können.',
    contact_text2: 'Bitte stellen sie uns jeden ihrer fragen.',
    contact_text3: 'Ein gerader * ist nötig',
    LastQuery: 'Letzte auskunft.',
    batchNumber: 'Produktionschargennummer',
    qualityCode: 'Qualitätscodes',
    productName: 'Name des Produkts',
    ProductionDate: 'Produktionsdatum'
}