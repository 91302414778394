import requests from './request';


//关于我们
export const AboutUs = (data) => requests({ url: `/api/Content/GetAbout`, data: data, method: "get" })

//创新
export const GetInnovate = (data) => requests({ url: `/api/Content/GetInnovate`, data: data, method: "get" })

//新闻列表
export const GetNewsList = (data) => requests({ url: `/api/News/GetNewsList`, data: data, method: "get" })

//新闻详情
export const GetNewsDetail = (data) => requests({ url: `/api/News/GetDetail`, data: data, method: "post" })

//获取产品分类
export const GetProductCategory = (data) => requests({ url: `/api/Product/GetProductCategory`, data: data, method: "get" })

//获取产品列表
export const GetProduct = (data) => requests({ url: `/api/Product/GetProduct`, data: data, method: "post" })

//获取产品详情
export const GetProductDetail = (data) => requests({ url: `/api/Product/GetProductDetail`, data: data, method: "post" })

//验证防伪码
export const CheckTrueCode = (data) => requests({ url: `/api/Product/CheckTrueCode`, data: data, method: "post" })

//获取分析分类
export const GetAnalysisCategory = (data) => requests({ url: `/api/Analysis/GetAnalysisCategory`, data: data, method: "post" })

//获取分析文件列表
export const GetAnalysis = (data) => requests({ url: `/api/Analysis/GetAnalysis`, data: data, method: "post" })

//提交反馈
export const PutFeedback = (data) => requests({ url: `/api/Content/PutFeedback`, data: data, method: "post" })

//获取网站信息
export const GetWebInfo = (data) => requests({ url: `/api/Content/GetWebInfo`, data: data, method: "post" })

//获取网站底部产品
export const GetBottomProduct = (data) => requests({ url: `/api/Product/GetBottomProduct`, data: data, method: "post" })

//获取首页数据
export const GetIndexData = (data) => requests({ url: `/api/Content/GetIndexData`, data: data, method: "post" })

