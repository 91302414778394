export const lang = {
    about: 'about us',
    news: 'news',
    innvoations: "innvoations",
    products: 'products',
    checkCode: 'check Code',
    analysis: 'analysis',
    contact: 'contact',
    check: 'check',
    check_text1: 'Our product has special protection in the form of a unique code. On this page you can check the authenticity of the item you bought by entering the code and clicking the "Check"',
    check_text2: 'Enter the authentication code and batch number of the product here',
    analysis_text1: 'TEST RESULTS',
    analysis_text2: 'All chromotography tests have been performed by an independent GMP-certified accredited lab Simec AG (www.simec.ch). All results are verifiable! Each report contains its individual number; for example C34804 for Boldenone Undecylenate. You can at any moment directly contact Simec and provide particular report number to verify the authenticity of the report.',
    show_filter: 'SHOW FILTER',
    analysis_text3: 'Active component',
    search: "SEARCH",
    contact_text1: 'FOR GENERAL QUESTIONS ABOUT PHARMACOM LABS YOU CAN USE THE FORM BELOW.',
    contact_text2: 'Please feel free to ask us any question you want.',
    contact_text3: 'Fields with * are required.',
    LastQuery: 'Last query',
    batchNumber: 'Batch Number',
    qualityCode: 'Authentication Code',
    productName: 'Product Name',
    ProductionDate: 'Production date'


}
