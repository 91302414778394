<template>
    <div>
        <div class="side-right w210 pull-right " @click="adUrl" v-if="web">
            <img :src="web.ad||''" >
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            web: null
        }
    },
    mounted() {
        this.getNav()
    },
    updated() {
    },
    methods: {
        adUrl() {
            window.location.href = this.web.ad_url;
        },
        stop() {
            console.log('stop')
        },
        getNav() {
            this.$API.GetWebInfo().then(res => {
                this.web = res.data.data
            })
        },
    }
}
</script>

<style >
.side-right {
    width: 210px;
    margin-left: 10px;
}

.side-right img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 500px) {
    .side-right {
        width: 100px;
        position: fixed;
        top: 40%;
        right: 20px
    }
}
</style>