import Vue from 'vue'
import App from './App.vue'
import router from "@/router/router"
import nav from "@/components/nav.vue"
import adv from "@/components/adv.vue"
import footer_nav from "@/components/footer_nav.vue"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.component('top-nav', nav)
Vue.component('adv', adv)
Vue.component('footer-nav', footer_nav)

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

var zh = 'en'
let lang = ['en', 'gn']

if (localStorage.getItem("zh") && lang.indexOf(localStorage.getItem("zh")) != -1) {
  zh = localStorage.getItem("zh")
} else {
  zh = localStorage.setItem("zh", 'en')
}

// if (zh == 'en-US') {
//   Cookies.set("think_var", "en", {})
// }
// if (zh == 'gn-Ge') {
//   Cookies.set("think_var", "gn", {})
// }

const i18n = new VueI18n({
  locale: zh, // 语言标识
  // this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'en': require('./assets/lang/en'),// 英文语言包
    'gn': require('./assets/lang/gn') // 德语语言包
  }
})

import * as API from '@/api';

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
    Vue.prototype.$API = API;
  }
}).$mount('#app')
