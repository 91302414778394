<template>
    <div>
        <div class="dis_b_c" style="min-height: 30px;">
            <header id="header-main" style="flex:1;">
                <div id="logo"><img :src="web.logo || ''" @click="routeTo('index')">
                </div>
            </header>
            <div class="iconfont icon-liebiao" @click="dialogTableVisible = true"></div>
        </div>
        <div id="nav-main" class="dis_c_c">
            <div class="dis_b_c" style="max-width: 1200px;width: 100%;">
                <div class="logo dis_c"><img :src="web.logo || ''"  @click="routeTo('index')">
                </div>
                <ul class="dis_c" style="flex-wrap: wrap;">

                    <li :class="{ active: $route.meta.nav == 'index' }" @click="routeTo('index')">
                        <div class="icon-shouye iconfont"></div>
                    </li>
                    <li :class="{ active: $route.meta.nav == 'about' }" @click="routeTo('about')">{{ $t('lang.about') }}
                    </li>
                    <li :class="{ active: $route.meta.nav == 'news' }" @click="routeTo('news')">{{ $t('lang.news') }}</li>
                    <li :class="{ active: $route.meta.nav == 'innovations' }" @click="routeTo('innovations')">{{
                        $t('lang.innvoations') }}
                    </li>
                    <li :class="{ active: $route.meta.nav == 'products' }" @click="routeTo('productsCate')">{{
                        $t('lang.products') }}</li>
                    <li :class="{ active: $route.meta.nav == 'CHECKCODE' }" @click="routeTo('checkCode')">{{
                        $t('lang.checkCode') }}</li>
                    <!-- <li :class="{ active: $route.meta.nav == 'dealers' }" @click="routeTo('dealers')">Dealers</li> -->
                    <li :class="{ active: $route.meta.nav == 'analysis' }" @click="routeTo('analysis')">{{
                        $t('lang.analysis') }}</li>
                    <li :class="{ active: $route.meta.nav == 'contact' }" @click="routeTo('contact')">{{ $t('lang.contact')
                    }}</li>
                    <li class="icon-duoyuyan iconfont" @click="changelan"></li>
                </ul>
            </div>
        </div>
        <div class="pc_line">

        </div>
        <transition name='rank' mode="out-in">
            <div class="menu" @click="dialogTableVisible = false" v-if="dialogTableVisible">
                <div class="menu_center" @click.stop="stop">
                    <ul>
                        <li :class="{ active: $route.meta.nav == 'index' }" @click="routeTo('index')" class="dis_b_c">
                            <div class="icon-shouye iconfont"></div>
                            <div class="icon-duoyuyan iconfont" @click="changelan"></div>
                        </li>
                        <li :class="{ active: $route.meta.nav == 'about' }" @click="routeTo('about')">{{ $t('lang.about') }}
                        </li>
                        <li :class="{ active: $route.meta.nav == 'news' }" @click="routeTo('news')">{{ $t('lang.news') }}
                        </li>
                        <li :class="{ active: $route.meta.nav == 'innovations' }" @click="routeTo('innovations')">
                            {{ $t('lang.innvoations') }}
                        </li>
                        <li :class="{ active: $route.meta.nav == 'products' }" @click="routeTo('productsCate')">{{
                            $t('lang.products') }}
                        </li>
                        <li :class="{ active: $route.meta.nav == 'CHECKCODE' }" @click="routeTo('checkCode')">{{
                            $t('lang.checkCode') }}
                        </li>
                        <!-- <li :class="{ active: $route.meta.nav == 'dealers' }" @click="routeTo('dealers')">Dealers</li> -->
                        <li :class="{ active: $route.meta.nav == 'analysis' }" @click="routeTo('analysis')">{{
                            $t('lang.analysis') }}</li>
                        <li :class="{ active: $route.meta.nav == 'contact' }" @click="routeTo('contact')">{{
                            $t('lang.contact') }}</li>
                    </ul>

                </div>

            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogTableVisible: false,
            web:null
        }
    },
    mounted() {
        this.getFooter()
    },
    updated() {
    },
    methods: {
        getFooter() {
            this.$API.GetWebInfo().then(res => {
                this.web = res.data.data
            })
        },
        stop() {
            console.log(stop)
        },
        changelan() {
            let zh = localStorage.getItem("zh")
            if (zh == 'en') {
                localStorage.setItem("zh", "gn");
                this.$i18n.locale = 'gn'
            } else {
                console.log(zh)
                localStorage.setItem("zh", "en");
                this.$i18n.locale = 'en'
            }
            location.reload()

        },
        routeTo(name) {
            this.dialogTableVisible = false
            this.$router.push({
                name: name
            })
        }
    }
}
</script>

<style>
.rank-enter-active,
.rank-leave-active {
    transition: all 0.s ease;
    overflow: hidden;
}

.rank-enter {
    transform: translateX(-50%);
    opacity: 0;
}

.rank-enter-to {
    transform: translateX(0%);
    opacity: 1;
}

.rank-leave {
    transform: translateX(0%);
    opacity: 1;
}

.rank-leave-to {
    transform: translateX(-50%);
    opacity: 0;
}

#header-main {
    position: relative;
    height: 75px;
    margin-bottom: 60px;
    display: none;
}

#header-main #logo {
    position: absolute;
    left: 2px;
    bottom: 0;
    cursor: pointer;
}

#nav-main {
    margin: 0 0 10px;
    background: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    /* border-radius: 8px 8px 0 0; */
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}


#nav-main .logo {
    height: 100%;
    cursor: pointer;
}

#nav-main .logo img {
    height: 30px;
    width: auto;
}

#nav-main ul {
    display: flex;
}

#nav-main ul li {
    list-style: none;
    padding: 0 20px;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 12px;
    color: #3a3a3a;
    text-transform: uppercase;
    display: block;
    height: 50px;
    font-weight: 700;
    /* text-shadow: 1px 1px 0px #000; */
    position: relative;
}




#nav-main ul li:hover {
    color: #2952a3 !important;
    /* box-shadow: inset 0px 0px 50px 5px rgba(0, 0, 0, 0.75); */
}


#nav-main ul .icon-duoyuyan {
    font-size: 25px;
    cursor: pointer;
}

#nav-main ul .active {
    color: #2952a3 !important;
    /* box-shadow: inset 0px 0px 50px 5px rgba(0, 0, 0, 0.75); */
}

#nav-main ul .active::after {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    animation: active 0.5s ease;
    background-color: #2952a3;
}

@keyframes active {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.menu_center {
    position: absolute;
    top: 0;
    left: 0;
    width: 60vw;
    height: 100vh;
    background: #333333;
    z-index: 1000;
    color: #fff;
}

.menu_center ul li {
    padding: 20px;
    box-sizing: border-box;
    border-bottom: solid 1px #eee;
    text-transform: uppercase;
}

.icon-liebiao {
    font-size: 25px;
    margin: 0 20px;
    display: none;
}

.icon-duoyuyan {
    font-size: 25px;
    color: #fff;
    cursor: pointer;
}

.pc_line {
    height: 50px;
}

@media screen and (max-width: 500px) {
    #nav-main {
        display: none;
    }

    .pc_line {
        display: none;
    }

    #header-main {
        height: 30px;
        margin: 20px 0;
        display: block;
    }

    #header-main #logo {
        width: 110px;
    }

    .icon-liebiao {
        display: block;
    }
}</style>